import { ServiceRegion } from "@/const/service/region";
import i18n from "@/plugins/vue-i18n";
import { Channel, CONTENT_CHANNEL } from "@/views/cms/content/common/choices";
import { SEARCH_TYPE, SearchType } from "@/views/member/search/common/choices";
import type { AgeChoiceItem } from "@/const/service/common/types";
import type { ChoiceItem } from "@/common/types";
import type { FilterItem } from "@/components/filter/common/types";
import type { ServiceLanguageString } from "@/common/types";
import { singleContentBMFilterItems } from "@/components/filter/common/items";
import { ServiceLanguage } from "@/common/types";

//TODO: 추후 확실히 정해지면 바꿔야한다.
export class Taiwan extends ServiceRegion {
  getAgeChoices(): AgeChoiceItem[] {
    return [
      { text: i18n.t("cms.ageLimit.all").toString(), value: 0, color: "green" },
      {
        text: "15",
        value: 15,
        color: "orange",
      },
      {
        text: i18n.t("cms.ageLimit.adult").toString(),
        value: 18,
        color: "red",
      },
    ];
  }

  getAdultAge(): number {
    return 18;
  }

  getChannelChoices(): ChoiceItem<Channel>[] {
    return CONTENT_CHANNEL.getChoices().filter(
      (choice) => choice.value === Channel.ONE,
    );
  }

  genreRankingEnabled(): boolean {
    return true;
  }

  ch2Enabled(): boolean {
    return false;
  }

  possessionTicketEnabled(): boolean {
    return true;
  }

  getMemberSearchType(): ChoiceItem<string>[] {
    return Object.values(SEARCH_TYPE.getChoices()).filter((choice) =>
      [SearchType.USER_ID, SearchType.EMAIL, SearchType.CS_ID].includes(
        choice.value as SearchType,
      ),
    );
  }

  getMemberSearchTypeHint(): Partial<Record<SearchType, string>> {
    return {
      [SearchType.USER_ID]: i18n
        .t("member.search.searchTypeHints.USER_ID_HINT")
        .toString(),
      [SearchType.CS_ID]: i18n
        .t("member.search.searchTypeHints.CS_ID_HINT")
        .toString(),
      [SearchType.EMAIL]: i18n
        .t("member.search.searchTypeHints.GLO_EMAIL_HINT")
        .toString(),
    };
  }

  getMemberDataShow(): boolean {
    return false;
  }

  changeNormalBannerTextColorEnabled(): boolean {
    return true;
  }

  adEnabled(): boolean {
    return false;
  }

  webToAppEnabled(): boolean {
    return true;
  }

  bannerEventSpecialCardMaxLength(): number {
    return 4;
  }

  getServiceLanguages(): ServiceLanguageString[] {
    return [ServiceLanguage.TW];
  }

  combinationBMEnabled(): boolean {
    return false;
  }

  sendManuscriptToolEnabled(): boolean {
    return false;
  }

  getContentBMFilterItems(): FilterItem[] {
    return singleContentBMFilterItems();
  }

  showReleasedForFreeDateTime(): boolean {
    return true;
  }

  showKakaopageEpisodeGet(): boolean {
    return false;
  }

  showKakaopageOriginalNovelContentLink(): boolean {
    return false;
  }

  gidamooPlusEnabled(): boolean {
    return true;
  }

  freeTodayEnabled(): boolean {
    return false;
  }

  freePublishingBadgeEnabled(): boolean {
    return true;
  }

  getHotMainCardSubTitleMaxLength(): number {
    return 24;
  }

  getHotMainCardTitleTextMaxLength(): number {
    return 13;
  }

  showMemberAgeCredentials(): boolean {
    return true;
  }

  showTopHotMainCardVideoFileUploader(): boolean {
    return true;
  }
}
